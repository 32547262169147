/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import './App.css'
import {
    BrowserRouter,
    Route,
    Routes,
    Navigate,
    Outlet,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Home from './Home'
import SignupPage from './pages/SignUpPage'
import LoginPage from './pages/LoginPage'
import NavBar from './NavBar'
import VerifyPage from './pages/VerifyPage'
import GoogleVerifyPage from './pages/GoogleVerifyPage'
import PricingPage from './pages/PricingPage'
import { ProgressSubscriptionPage } from './pages'
import PayForSubscription from './pages/PayForSubscription'
import PaymentSuccessful from './pages/PaymentSuccessful'
import AppShell from './ApplicationShell'
import ConvertVideo from './pages/protected/ConvertVideo'
// import UploadVideo from './pages/protected/UploadVideo'
import ProcessedVideos from './pages/protected/ProcessedVideos'
import TermsPage from './pages/TermsPage'
import PrivacyPage from './pages/PrivacyPage'

function ProtectedRoutes({ isRouteAvailable, redirectTo }) {
    return isRouteAvailable ? <Outlet /> : <Navigate replace to={redirectTo} />
}

const environment = process.env.NODE_ENV
const stripePublishableKey =
    environment === 'production'
        ? process.env.REACT_APP_STRIPE_SECRET_PRODUCTION
        : process.env.REACT_APP_STRIPE_SECRET

const stripePromise = loadStripe(stripePublishableKey)

export default function App() {
    // if logged in show inner app else show normal nav bar etc
    const isProtectedRouteAvailable = useSelector(
        (state) => state.user?.subscription?.isActive && state.auth?.isLoggedIn,
    )

    const clientSecret = useSelector(
        // we probably shouldnt be storing this in redux for everyone to see?
        (state) => state.user.createdSubscriptionSecret?.clientSecret,
    )
    const selectedPriceTier = localStorage.getItem('@selectedPriceTier')

    const options = {
        // passing the client secret obtained from the server
        clientSecret,
    }

    console.log('environment: ', environment)
    console.log('stripePublishableKey: ', stripePublishableKey)
    console.log('clientSecret: ', clientSecret)
    console.log('selectedPriceTier: ', selectedPriceTier)

    if(clientSecret){
        console.log('CLIENT SECRET YES')
        return ( // i made this conditional - before Elements always ran
            <Elements stripe={stripePromise} options={options}>
                <div className='App'>
                    <BrowserRouter>
                        {isProtectedRouteAvailable ? (
                            <AppShell>
                                <Routes>
                                    <Route
                                        element={
                                            <ProtectedRoutes
                                                isRouteAvailable={
                                                    isProtectedRouteAvailable
                                                }
                                                redirectTo='/'
                                            />
                                        }
                                    >
                                        {/* <ProtectedRoutes path="/dashboard" Component={Dashboard} redirectTo="/" /> */}
                                        <Route
                                            path='/dashboard'
                                            element={<ConvertVideo />}
                                        />
                                        {/* <Route path="/uploadVideo" element={<UploadVideo />} /> */}
                                        <Route
                                            path='/processedVideos'
                                            element={<ProcessedVideos />}
                                        />
                                    </Route>
                                </Routes>
                            </AppShell>
                        ) : (
                            <>
                                <NavBar />
                                <Routes>
                                    <Route path='/' element={<Home />} />

                                    <Route
                                        path='/login'
                                        element={<LoginPage />}
                                    />

                                    <Route
                                        path='/signup'
                                        element={<SignupPage />}
                                    />

                                    <Route
                                        path='/verify'
                                        element={<VerifyPage />}
                                    />

                                    <Route
                                        path='/googleVerify'
                                        element={<GoogleVerifyPage />}
                                    />

                                    <Route
                                        path='/pricing'
                                        element={<PricingPage />}
                                    />

                                    <Route
                                        path='/terms'
                                        element={<TermsPage />}
                                    />

                                    <Route
                                        path='/privacy'
                                        element={<PrivacyPage />}
                                    />

                                    <Route
                                        path='/progressSubscription'
                                        element={<ProgressSubscriptionPage />}
                                    />

                                    <Route
                                        path='/payForSubscription'
                                        element={<PayForSubscription />}
                                    />

                                    {/* <Route path="/paymentStatus" element={<PaymentStatus />} /> */}

                                    <Route
                                        path='/paymentSuccessful'
                                        element={<PaymentSuccessful />}
                                    />

                                    <Route
                                        element={
                                            <ProtectedRoutes
                                                isRouteAvailable={
                                                    isProtectedRouteAvailable
                                                }
                                                redirectTo='/'
                                            />
                                        }
                                    >
                                        <Route
                                            path='/dashboard'
                                            element={<ConvertVideo />}
                                        />
                                    </Route>
                                </Routes>
                            </>
                        )}
                    </BrowserRouter>
                </div>
            </Elements>
        )
    }

    if(!clientSecret){
        console.log('CLIENT SECRET NO')
        return (
            <div className='App'>
                <BrowserRouter>
                    {isProtectedRouteAvailable ? (
                        <AppShell>
                            <Routes>
                                <Route
                                    element={
                                        <ProtectedRoutes
                                            isRouteAvailable={
                                                isProtectedRouteAvailable
                                            }
                                            redirectTo='/'
                                        />
                                    }
                                >
                                    {/* <ProtectedRoutes path="/dashboard" Component={Dashboard} redirectTo="/" /> */}
                                    <Route
                                        path='/dashboard'
                                        element={<ConvertVideo />}
                                    />
                                    {/* <Route path="/uploadVideo" element={<UploadVideo />} /> */}
                                    <Route
                                        path='/processedVideos'
                                        element={<ProcessedVideos />}
                                    />
                                </Route>
                            </Routes>
                        </AppShell>
                    ) : (
                        <>
                            <NavBar />
                            <Routes>
                                <Route path='/' element={<Home />} />

                                <Route path='/login' element={<LoginPage />} />

                                <Route
                                    path='/signup'
                                    element={<SignupPage />}
                                />

                                <Route
                                    path='/verify'
                                    element={<VerifyPage />}
                                />

                                <Route
                                    path='/googleVerify'
                                    element={<GoogleVerifyPage />}
                                />

                                <Route
                                    path='/pricing'
                                    element={<PricingPage />}
                                />

                                <Route path='/terms' element={<TermsPage />} />

                                <Route
                                    path='/privacy'
                                    element={<PrivacyPage />}
                                />

                                <Route
                                    path='/progressSubscription'
                                    element={<ProgressSubscriptionPage />}
                                />

                                <Route
                                    path='/payForSubscription'
                                    element={<PayForSubscription />}
                                />

                                {/* <Route path="/paymentStatus" element={<PaymentStatus />} /> */}

                                <Route
                                    path='/paymentSuccessful'
                                    element={<PaymentSuccessful />}
                                />

                                <Route
                                    element={
                                        <ProtectedRoutes
                                            isRouteAvailable={
                                                isProtectedRouteAvailable
                                            }
                                            redirectTo='/'
                                        />
                                    }
                                >
                                    <Route
                                        path='/dashboard'
                                        element={<ConvertVideo />}
                                    />
                                </Route>
                            </Routes>
                        </>
                    )}
                </BrowserRouter>
            </div>
        )
    }
    
}
